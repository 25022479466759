import {
  computed,
  ComputedRef,
  watch,
  Ref,
  ref,
  shallowRef,
} from '@vue/composition-api';

import { consoleEnv } from '@ax/console-env';
import { consoleI18n } from '@ax/console-i18n';
import {
  DEFAULT_TABLE_PAGINATION,
  TABLE_ITEMS_PER_PAGE,
  TableHeader,
} from '@ax/cosmos/components/Table/table.models';
import {
  DataTablePageState,
  DataTableState,
  VuetifyDataTableState,
} from '@ax/data-table-state';
import { showStandardHttpErrorNotification } from '@ax/notifications';
import { useCurrentOrgId, useRoute, useRouter } from '@ax/vue-utils/router';
import {
  QueryState,
  QueryStateParametersConfiguration,
} from '@ax/url-query-state';
import { StorageConfig } from '@ax/web-storage-wrapper';

import {
  getActivityLog,
  ACTIVITY_LOG_ENDPOINT,
} from '@/services/activity-log.service';
import { ActivityLogResponse } from '@/models/activity-log';
import { getEndRangeFromTerm, getStartRangeFromTerm } from '@/utils/date-time';

/**
 * ACTIVITY LOG
 *
 * Provides the constants, helper functions, and composition function necessary to quickly
 * and consistently generate the Activity Log table so it can easily be used in multiple locations.
 */

/**
 * The potential query param options for the Activity Log table/ API.
 */
export interface ActivityLogQueryState
  extends DataTablePageState<ActivityLogResponse> {
  policy_id: string;
  policy_type_name: string;
  date_range: string;
  start_date: string;
  end_date: string;
  device_names: string[];
  details_search: string[];
  internal: number;
}

/**
 * The object returned from the useActivityLog function.
 */
export interface UseActivityLogResponse {
  exportCsvUrl: ComputedRef<string>;
  getActivityLogReport: (apiQueryString: string) => Promise<void>;
  lastApiQuery: Ref<string | undefined>;
  logs: Ref<ActivityLogResponse[]>;
  orgId: ComputedRef<number>;
  queryState: Ref<ActivityLogQueryState>;
  tableState: Ref<VuetifyDataTableState<ActivityLogResponse>>;
  updateQueryState: Function;
}

/**
 * The object returned from the useActivityLogExpansion function.
 */
export interface UseActivityLogExpansionResponse {
  expandedRows: Ref<ActivityLogResponse[]>;
  isSingleExpand: Ref<boolean>;
  orgId: ComputedRef<number>;
  toggleAllVisibleLogs: Function;
}

/**
 * The header configurations for the Activity Log table.
 * Sets the text, sort-ability, key-value, etc.
 */
export const activityLogHeaders: TableHeader[] = [
  {
    text: consoleI18n.t('reports.activityLog.deviceName:label'),
    sortable: true,
    value: 'server_name',
  },
  {
    text: consoleI18n.t('reports.activityLog.policyName:label'),
    sortable: true,
    filterable: false,
    value: 'policy_name',
  },
  {
    text: consoleI18n.t('reports.activityLog.eventTime:label'),
    sortable: true,
    filterable: false,
    value: 'create_time',
    width: '14rem',
  },
  {
    text: consoleI18n.t('reports.activityLog.eventType:label'),
    sortable: false,
    filterable: false,
    value: 'status',
  },
  {
    text: consoleI18n.t('reports.activityLog.logSummary:label'),
    sortable: false,
    value: 'data',
    width: '22rem',
  },
  {
    text: consoleI18n.t('reports.activityLog.fullLogs:label'),
    sortable: false,
    value: 'data-table-expand',
    align: 'center',
  },
];

/**
 * The footer configuration for the Activity Log table.
 * Sets the pagination options and static text.
 */
export const activityLogFooter = {
  'items-per-page-options': TABLE_ITEMS_PER_PAGE,
  'items-per-page-text': consoleI18n.t(
    'reports.activityLog.logsPerPage:button',
  ),
};

/**
 * Compiles queryStateConfig param which is passed to DataTableState.synchronize()
 *
 * @param orgId the current org id
 * @returns a QueryStateParametersConfiguration object representing the desired query configs
 */
function getActivityLogQueryStateConfiguration(
  orgId: number,
): QueryStateParametersConfiguration<ActivityLogQueryState> {
  return {
    o: { defaultValue: orgId },
    limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
    sort_dir: {
      defaultValue: 'desc',
      apiKeyTranslation: 'dir',
      legacyUiKeyTranslation: 'sort',
    },
    sort_by: {
      defaultValue: 'create_time',
      apiKeyTranslation: 'sort',
      legacyUiKeyTranslation: 'order_by',
      apiValueTranslations: new Map().set('server_name', 'device_name'),
    },
    page: { defaultValue: 1 },
    columns: { defaultValue: [] },
    policy_id: { defaultValue: '', apiKeyTranslation: 'policyId' },
    policy_type_name: {
      defaultValue: '',
      apiKeyTranslation: 'policyTypeName',
    },
    date_range: {
      defaultValue: 'today',
      excludeFromApiUrl: true,
      storeInBrowser: true,
    },
    device_names: { defaultValue: [], apiKeyTranslation: 'deviceNames' },
    details_search: { defaultValue: [], apiKeyTranslation: 'q' },
    start_date: {
      defaultValue: '',
      storeInBrowser: true,
      apiKeyTranslation: 'startDateTime',
    },
    end_date: {
      defaultValue: '',
      storeInBrowser: true,
      apiKeyTranslation: 'endDateTime',
    },
    filter_panel_open: {
      defaultValue: true,
      storeInBrowser: true,
      excludeFromBrowserUrl: true,
      excludeFromApiUrl: true,
    },
    internal: {
      defaultValue: 1,
      excludeFromBrowserUrl: true,
    },
  };
}

/**
 * Composition function to generate the states, values, and interactivity necessary to run the
 * Activity Log table.
 *
 * @param queryStateParametersConfiguration Optional. The query params as a key-value object.
 * @param customStorageConfig Optional. The storage config key and location to save the state.
 * @returns The generated states, values, and interactivity for the Activity Log table.
 */
export function useActivityLog(
  queryStateParametersConfiguration?: QueryStateParametersConfiguration<ActivityLogQueryState>,
  customStorageConfig?: StorageConfig,
): UseActivityLogResponse {
  const orgId = useCurrentOrgId();
  const logs = shallowRef<ActivityLogResponse[]>([]);
  const lastApiQuery = shallowRef<string>();

  const exportCsvUrl = computed(
    () =>
      `${consoleEnv.API_BASE_URL}/${ACTIVITY_LOG_ENDPOINT}/csv?${lastApiQuery.value}`,
  );

  /**
   * Compile DataTableState.synchronize() queryStateConfig param, a static object.
   * No reactivity is necessary (should not change).
   */
  const queryStateConfig: QueryStateParametersConfiguration<ActivityLogQueryState> =
    queryStateParametersConfiguration ||
    getActivityLogQueryStateConfiguration(orgId.value);

  /**
   * Sets where the table configuration is stored.
   * No reactivity is necessary (should not change).
   */
  const storageConfig: StorageConfig = customStorageConfig || {
    key: 'ax-activity-log-prefs',
    store: localStorage,
  };

  const { tableState, queryState, updateQueryState } =
    DataTableState.synchronize<ActivityLogResponse, ActivityLogQueryState>(
      useRoute(),
      useRouter(),
      queryStateConfig,
      {
        storageConfig,
        callback: ({ apiQuery }) => {
          const apiQueryString =
            QueryState.toApiUrlQueryStringWithBrackets(apiQuery);
          lastApiQuery.value = apiQueryString;
          getActivityLogReport(apiQueryString);
        },
        onBeforeApiUrlGeneration: (state) => {
          const { date_range } = state;
          if (date_range !== 'custom') {
            const start_date = getStartRangeFromTerm(date_range);
            const end_date = getEndRangeFromTerm(date_range);
            return { ...state, start_date, end_date };
          }
          return state;
        },
      },
    );

  // If the orgId changes, update the query state
  watch(
    orgId,
    (next, prev) => {
      if (prev && next !== prev) {
        updateQueryState({
          o: next,
          policy_type_name: '',
          policy_id: '',
          details_search: [],
          device_names: [],
        });
      }
    },
    {
      immediate: true,
    },
  );

  /**
   * Gets the logs from the endpoint and sets the logs value to the response.
   * Handles if the endpoints returns an error.
   *
   * @param apiQueryString The query string used to filter and sort the list of logs.
   * @returns A promise for when the endpoint returns or errors.
   */
  function getActivityLogReport(apiQueryString: string): Promise<void> {
    return getActivityLog(apiQueryString)
      .then((response) => {
        logs.value = response;
      })
      .catch(showStandardHttpErrorNotification);
  }

  return {
    getActivityLogReport,
    orgId,
    lastApiQuery,
    logs,
    tableState,
    queryState,
    updateQueryState,
    exportCsvUrl,
  };
}

/**
 * Composition function focused on generating the log row visibility state.
 * Used to limit the amount of query sync emissions to keep api calls to a minimum,
 * since this state does not necessitate getting new data.
 *
 * @returns The generated log row visibility state.
 */
export function useActivityLogExpansion(): UseActivityLogExpansionResponse {
  const orgId = useCurrentOrgId();
  const isSingleExpand = ref(false);
  const expandedRows = shallowRef<ActivityLogResponse[]>([]);

  /**
   * Toggles whether or not all of the log rows are expanded.
   *
   * @param isOpen Whether or not a single log row is expanded.
   * @param localLogs Whether or not all log rows are expanded.
   */
  function toggleAllVisibleLogs(
    isOpen: boolean,
    localLogs: ActivityLogResponse[],
  ): void {
    isSingleExpand.value = isOpen;
    expandedRows.value = isOpen ? localLogs : [];
  }

  return { orgId, isSingleExpand, expandedRows, toggleAllVisibleLogs };
}
