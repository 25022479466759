import { render, staticRenderFns } from "./TreeSelect.vue?vue&type=template&id=4a4c4978&scoped=true&"
import script from "./TreeSelect.vue?vue&type=script&lang=ts&"
export * from "./TreeSelect.vue?vue&type=script&lang=ts&"
import style0 from "./TreeSelect.vue?vue&type=style&index=0&id=4a4c4978&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a4c4978",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VTreeview})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
