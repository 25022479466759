import { computed, SetupContext } from '@vue/composition-api';

import { Agents } from '@/models/agents';
import {
  AGENTS_REQUEST,
  AGENTS_GET_AGENTS,
  AGENTS_IS_LOADING,
} from '@/store/actions/agents';

export default function useAgents(context: SetupContext) {
  const loading = computed<boolean>(
    () => context.root.$store.getters[AGENTS_IS_LOADING],
  );
  const orgId = computed(() => context.root.$store.state.route.query.org);
  const agents = computed<Agents>(
    () => context.root.$store.getters[AGENTS_GET_AGENTS],
  );

  if (!agents.value) {
    context.root.$store.dispatch(AGENTS_REQUEST, orgId.value);
  }

  return { agents, loading };
}
