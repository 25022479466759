import Vue from 'vue';

import {
  computed,
  onUnmounted,
  Ref,
  ref,
  SetupContext,
} from '@vue/composition-api';
import { AxiosResponse } from 'axios';

import { constVoid } from '@ax/function-utils';
import { showStandardHttpErrorNotification } from '@ax/notifications';
import { useCurrentOrgId, useRouter } from '@ax/vue-utils/router';
import { consoleI18n } from '@ax/console-i18n';
import {
  DeviceStatuses,
  PolicyStatuses,
} from '@ax/data-services-devices/models';
import { Device } from '@/models/devices';
import {
  getDeviceDetails,
  updateDeviceDetails,
  postDeviceQueue,
  removeDevice,
  clearDevicesCache,
} from '@/services/devices.service';
import { DeviceCommands } from '@/types/device-commands';
import { capitalize } from '@/utils/display-text';
import { getCurrentUser } from './useUser';

// "Device Status" part of device status (sorry)
export function deviceDeviceStatus(status: DeviceStatuses): string {
  switch (status) {
    case DeviceStatuses.needsReboot:
      return 'Reboot Required';
    case DeviceStatuses.working:
      return 'Pending Update';
    default:
      return status.replace('-', ' ');
  }
}
export function devicePolicyStatus(
  device: Partial<Device>,
): PolicyStatuses | undefined {
  if (device.pending) {
    return PolicyStatuses.scheduled;
  }
  if (device.compliant) {
    return PolicyStatuses.compliant;
  }
  return device.status?.policy_status;
}

export function deviceStatusText(device: Partial<Device>): string {
  return [
    device.status?.device_status
      ? deviceDeviceStatus(device.status.device_status!)
      : '',
    device.status?.agent_status,
    devicePolicyStatus(device),
    device.exception ? 'Excluded From Reports' : '',
  ]
    .map((status) => status && capitalize(status?.replace(' ', '-')))
    .join(' ');
}

export function useDevice(
  context: SetupContext,
  getDeviceGroups,
  orgId: Ref<number> = useCurrentOrgId(),
) {
  const deviceId = computed(() => context.root.$route.params.deviceId);
  const device = ref<Device>({} as Device);
  let lastDeviceGroups;
  let deviceTimerId;
  pollDevice(orgId.value.toString(), deviceId.value);

  onUnmounted(() => {
    clearDevicePollingTimer();
  });

  const router = useRouter();

  let deviceRequestFailureCount = 0;

  function pollDevice(
    orgId: string | number,
    deviceId: string | number,
    showLoader = true,
  ) {
    lastDeviceGroups = device.value?.server_group_id;

    getDeviceDetails(orgId, deviceId, showLoader)
      .then((response) => {
        device.value = response;
        deviceRequestFailureCount = 0;

        if (
          lastDeviceGroups !== device.value.server_group_id &&
          deviceTimerId
        ) {
          getDeviceGroups(orgId);
        }

        if (!deviceTimerId) {
          startDevicePollingTimer();
        }
      })
      .catch((error) => {
        if (!error) {
          // EVG-582 This happens often enough that it's worth tracking
          console.error(
            new Error(
              'Request for device details was aborted or otherwise failed with no response code',
            ),
          );
        } else {
          console.error(error);
          // If there is an error with the initial call to getDeviceDetails
          if (!deviceTimerId) {
            if (error.status === 404) {
              router.replace({
                path: '/devices',
                query: { o: String(orgId) },
              });
            } else {
              showStandardHttpErrorNotification(error);
            }
          }
          // Otherwise, won't spam user with notifications if there are errors during device polling
        }

        // Stop polling the device if it is continually failing
        deviceRequestFailureCount += 1;
        if (deviceRequestFailureCount > 4) {
          clearDevicePollingTimer();
        }
      });
  }

  function startDevicePollingTimer() {
    deviceTimerId = setInterval(() => {
      pollDevice(orgId.value.toString(), deviceId.value, false);
    }, 20000);
  }

  function clearDevicePollingTimer() {
    clearInterval(deviceTimerId);
  }

  function saveDeviceDetails(payload: Device): Promise<void> {
    const orgId = payload.organization_id;
    const deviceId = payload.id;

    return updateDeviceDetails(orgId!, deviceId!, payload)
      .then(() => {
        device.value!.display_name =
          payload.display_name || payload.custom_name;
        Vue.notify({
          group: 'snackbar',
          text: 'Success! Device has been updated.',
          type: '{ "card": "success" }',
        });
      })
      .then(() => {
        /**
         * This could potentially result in new tags being created, as such
         * we should refresh the `User` so that the tags it has are up to date.
         */
        if (payload.tags !== undefined && orgId) {
          return getCurrentUser(`${orgId}`, true).then(constVoid);
        }
      })
      .catch(showStandardHttpErrorNotification);
  }

  async function remove() {
    await removeDevice(orgId.value, device.value.id);

    // invalidate the entire devices cache since the removed device could be in the response of any query
    clearDevicesCache();

    router.push({ name: 'devices' });
    Vue.notify({
      group: 'snackbar',
      text: consoleI18n.t('devices.removed:notification'),
      type: '{ "card": "success" }',
    });
  }

  return { device, saveDeviceDetails, remove };
}

export function submitDeviceQueue(
  device: Device,
  command: DeviceCommands,
  execTime?: string | null,
  args?: string | null,
): Promise<AxiosResponse> {
  if (device.id && device.organization_id) {
    return postDeviceQueue(
      device.id,
      command,
      device.organization_id,
      execTime,
      args,
    );
  }

  return Promise.reject(new Error('Device info appears to be missing.'));
}
